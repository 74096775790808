import _ from 'underscore'
import ApplicationController from 'controllers/application_controller'

export default class extends ApplicationController {

  static targets = [
    'main',
    'title',
    'content'
  ]

  static classes = [
    'collapsed',
    'expanded',
    'framed',
    'fullscreen',
    'overflowing',
    'scrollable',
    'scrolledPastStart',
    'scrolledToEnd'
  ]

  static values  = {
    defaultTitle:         String,
    floating:             Boolean,
    communicatedWidth:    Number,
    communicatedHeight:   Number,
    parentViewportWidth:  Number,
    parentViewportHeight: Number,
    embedId:              String,
    scrollMargin:         { type: Number, default: 0 }
  }

  static outlets = [
    'realtime-widget',
  ]

  get embeddedElement () {
    return (
      this.hasMainTarget &&
      this.mainTarget
    ) || this.element
  }

  get embeddedStyle () {
    return window.getComputedStyle(this.embeddedElement)
  }

  get embeddedWidth () {
    let style = this.embeddedStyle

    return parseInt(style.marginLeft)  +
           parseInt(style.marginRight) +
           this.embeddedElement.offsetWidth
  }

  get embeddedHeight () {
    let style = this.embeddedStyle

    return parseInt(style.marginTop)    +
           parseInt(style.marginBottom) +
           this.embeddedElement.offsetHeight
  }

  set previousTitles (newArray) {
    this._previousTitles = newArray
  }

  get previousTitles () {
    return this._previousTitles || (this._previousTitles = [])
  }

  get expandCallbacks () {
    return this._expandCallbacks || (this._expandCallbacks = [])
  }

  get title () {
    return this.hasTitleTarget && this.titleTarget.innerHTML.trim()
  }

  set title (newTitle) {
    let currentTitle = this.title
    if (currentTitle && currentTitle.length) {
      this.previousTitles.push(currentTitle)
    }

    if (this.hasTitleTarget) {
      this.titleTarget.innerHTML = newTitle
    }
  }

  connect() {
    if (!this.hasEmbedIdValue) {
      let metaTag = document.querySelector('meta[name="embed-id"]')
      this.embedIdValue = metaTag && metaTag.getAttribute('content')
    }

    if (window.parent != window) {
      this.receiveMessageFromParent = this.receiveMessageFromParent.bind(this)
      this.reportWidgetSize         = this.reportWidgetSize.bind(this)
      this.setupForParentFrame      = this.setupForParentFrame.bind(this)

      _.defer(this.setupForParentFrame)

      if (!this.hasDefaultTitleValue) {
        this.defaultTitleValue = this.title
      }

      this.scrollContent()
    }

    document.embeddedController = this
  }

  disconnect () {
    document.embeddedController = null
  }

  setupForParentFrame() {
    this.element.classList.add(this.framedClass)
    this.element.querySelectorAll('form').forEach(
      (form) => this.setupFramingOnForm(form)
    )

    if (window.addEventListener) {
      window.addEventListener('message', this.receiveMessageFromParent, false)
      window.addEventListener('resize',  this.reportWidgetSize)
    }

    this.requestParentViewportDimensions()
    this.reportWidgetSize()
  }

  setupFramingOnForm(form) {
    if (form.querySelectorAll('input[name="window_framed"]').length == 0) {
      let input = document.createElement('input')
      input.type  = 'hidden'
      input.name  = 'window_framed'
      input.value = '1'

      form.appendChild(input)
    }
  }

  resetTitle() {
    if (this.hasTitleTarget) {
      let previousTitle = this.previousTitles.pop()
      if (previousTitle && previousTitle.length) {
        this.titleTarget.innerHTML = previousTitle
      } else {
        this.clearTitle()
      }
    }
  }

  clearTitle() {
    if (this.hasTitleTarget) {
      this.titleTarget.innerHTML = this.defaultTitle
    }
  }

  // COMMUNICATION:

  receiveMessageFromParent (event) {
    let message = event.data

    if (message.embedId == this.embedIdValue) {
      let subject = message.subject
      let args    = message.arguments
      let name    = `process${subject.charAt(0).toUpperCase()}${subject.slice(1)}Message`
      let method  = this[name]

      if (method && typeof(method) == 'function') {
        method.call(this, ...args)
      } else {
        console.warn('receiveMessageFromParent: unsupported message', name, subject, args)
      }
    }
  }

  postMessageToParent(subject, ...args) {
    let message = {
      subject:   subject,
      embedId:   this.embedIdValue,
      arguments: args
    }

    if (window.parent && window.parent.postMessage) {
      window.parent.postMessage(message, '*')
    }
  }

  // MESSAGING:

  processSetKeyValueMessage(key, value) {
    if (this.hasRealtimeWidgetOutlet) {
      this.realtimeWidgetOutlet.set(key, value)
    } else {
      console.warn('no realtime-widget to process setKeyValue', key, value)
    }

  }

  processViewportDimensionsMessage(width, height, status) {
    let firstTime = !(
      this.hasParentViewportWidthValue || this.hasParentViewportHeightValue
    )

    this.parentViewportWidthValue  = width
    this.parentViewportHeightValue = height

    if (this.floatingValue && firstTime) {
      if(status == 'collapsed') {
        this.collapseFloat()
        return
      }

      let parentViewportArea = width * height
      let embeddedArea       = this.embeddedHeight * this.embeddedWidth

      if ((embeddedArea / parentViewportArea) >= 0.5) {
        this.collapseFloat()
      } else {
        this.expandFloat(null, false)
      }
    }

    this.reportWidgetSize()
  }

  requestParentViewportDimensions() {
    this.postMessageToParent('requestViewportDimensions')
  }

  processExpandMessage(dimensions = {}) {
    this.expandFloat()
  }

  processCollapseMessage(dimensions = {}) {
    this.collapseFloat()
  }

  processOverflowMessage(dimensions = {}) {
    this.element.classList.add(this.overflowingClass)
  }

  processUnderflowMessage(dimensions = {}) {
    this.element.classList.remove(this.overflowingClass)
  }

  processFullscreenMessage(dimensions = {}) {
    this.element.classList.add(this.fullscreenClass)
  }

  processPartialScreenMessage(dimensions = {}) {
    this.element.classList.remove(this.fullscreenClass)
  }

  processRequestWidgetSizeMessage() {
    this.reportWidgetSize(true)
  }

  reportWidgetEvent(name, object = null) {
    this.postMessageToParent('widgetEvent', name, object)
  }

  reportWidgetSize(forceReport = false) {
    if (typeof(forceReport) != 'boolean') { forceReport = false }

    let width  = this.embeddedWidth
    let height = this.embeddedHeight

    let dimensionsValid   = (width > 0 && height > 0)
    let dimensionsChanged = (
      width  != this.communicatedWidthValue ||
      height != this.communicatedHeightValue
    )

    this.communicatedWidthValue  = width
    this.communicatedHeightValue = height

    if (forceReport || (dimensionsValid && dimensionsChanged)) {
      this.postMessageToParent('widgetSize', width, height)
    }

    this.scrollContent()
  }

  reportExpand() {
    this.postMessageToParent('widgetExpand', this.embeddedWidth, this.embeddedHeight)
  }

  reportCollapse() {
    this.postMessageToParent('widgetCollapse', this.embeddedWidth + 10, this.embeddedHeight)
  }

  // EVENTS:

  onExpand(callback) {
    this.expandCallbacks.push(callback)
  }

  scrollContent(event) {
    if (this.floatingValue && this.hasContentTarget) {
      let currentPosition = this.contentTarget.scrollTop
      let startPosition   = 0
      let endPosition     = this.contentTarget.scrollHeight - this.contentTarget.offsetHeight

      if (endPosition >= 0) {
        this.element.classList.add(this.scrollableClass)

        if (currentPosition > startPosition) {
          this.embeddedElement.classList.add(this.scrolledPastStartClass)
        } else {
          this.embeddedElement.classList.remove(this.scrolledPastStartClass)
        }

        if (currentPosition >= (endPosition - this.scrollMarginValue)) {
          this.embeddedElement.classList.add(this.scrolledToEndClass)
        } else {
          this.embeddedElement.classList.remove(this.scrolledToEndClass)
        }
      } else {
        this.element.classList.remove(this.scrollableClass)
        this.embeddedElement.classList.remove(this.scrolledPastStartClass)
        this.embeddedElement.classList.remove(this.scrolledToEndClass)
      }
    }
  }

  expandFloat(event, resetTitle = true) {
    if (resetTitle && this.previousTitles.length) {
      this.resetTitle()
    }

    this.element.classList.remove(this.collapsedClass)
    this.element.classList.add(this.expandedClass)

    this.reportWidgetSize()

    if (event) {
      event.preventDefault()
      this.expandCallbacks.forEach((callback) => callback.call(this))
    }

    this.reportExpand()
  }

  collapseFloat(event) {
    if (event) { event.preventDefault() }

    this.title = this.defaultTitleValue

    this.element.classList.add(this.collapsedClass)
    this.element.classList.remove(this.expandedClass)
    this.element.classList.remove(this.fullscreenClass)

    this.reportWidgetSize()
    this.reportCollapse()
  }
}