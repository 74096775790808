import FlatpickrController from 'controllers/flatpickr_controller'

export default class extends FlatpickrController {

  static get targets() {
    return [...super.targets]
  }

  static outlets = [
    'realtime-widget'
  ]

  /**
   * @returns {RealtimeWidgetController}
   */
  get widget() {
    return this.realtimeWidgetOutlet
  }

  /**
   * @returns {BookableController}
   */
  get bookable() {
    return this.widget && this.widget.booking
  }

  static values = {
    locale:                 { type: String, default: 'en'            },
    availableFrom:          { type: String, default: null            },
    availableTill:          { type: String, default: null            },
    firstDay:               { type: String, default: null            },
    currentDateFormat:      { type: String, default: 'l, j F, Y'     },
    selectLabel:            { type: String, default: 'Choose a date' },
    todayLabel:             { type: String, default: 'Today'         },
    currentWeekLabelFormat: { type: String, default: 'l'             },
    currentYearLabelFormat: { type: String, default: 'l, j F'        },
    otherYearLabelFormat:   { type: String, default: 'j F, Y'        }
  }

  // SETUP:

  connect () {
    this.flatpickrOptions = {
      static:            true,
      clickOpens:        false,
      appendTo:          this.element,
      minDate:           this.availableFromValue,
      maxDate:           this.availableTillValue,
      altInput:          true,
      altFormat:         this.currentDateFormatValue
    }

    super.connect()

    this.flatpickr.altInput.setAttribute('data-action', 'click->realtime-widget--date-select#toggleDatePicker')

    this.refresh()
  }

  disconnect () {
    super.disconnect()
  }

  // EVENTS:

  refresh () {
    if (this.value) {
      if (this.isToday) {
        this.currentDateFormatValue = this.escapeDateFormat(this.todayLabelValue)
      } else if (this.isCurrentWeek) {
        this.currentDateFormatValue = this.currentWeekLabelFormatValue
      } else if (this.isCurrentYear) {
        this.currentDateFormatValue = this.currentYearLabelFormatValue
      } else {
        this.currentDateFormatValue = this.otherYearLabelFormatValue
      }
    } else {
      this.currentDateFormatValue = this.otherYearLabelFormatValue
    }

    if (this.flatpickr) {
      if (this.hasAvailableFromValue) {
        this.setDatePickerConfig('minDate', this.flatpickr.parseDate(this.availableFromValue))
      } else {
        this.setDatePickerConfig('minDate', null)
      }

      if (this.hasAvailableTillValue) {
        let maxDate = this.flatpickr.parseDate(this.availableTillValue)
        this.setDatePickerConfig('maxDate', maxDate)
      } else {
        this.setDatePickerConfig('maxDate', null)
      }

      this.setDatePickerConfig('altFormat', this.currentDateFormatValue)
      this.flatpickr.redraw()
    }
  }

  changed (event) {
    this.widget.refresh()
    this.widget.reportEvent('date-changed', { date: this.value.toISOString().split('T').shift() })
  }

  toggleDatePicker (event) {
    if (this.flatpickr) {
      this.flatpickr.toggle()
    }
  }

  openDatePicker (event) {
    if (this.flatpickr) {
      this.flatpickr.open()
    }
  }

  closeDatePicker (event) {
    if (this.flatpickr) {
      this.flatpickr.close()
    }
  }

  // DATE HELPERS:

  escapeDateFormat (string) {
    return string.replace(/[dDljJwWFmnMUuyYZHhGiSsK]/g, '\\$&')
  }

  get value () {
    return super.value
  }

  set value(newValue) {
    super.value = newValue
    this.refresh()
  }

  get humanValue() {
    let date = this.value

    if (this.flatpickr && date) {
      if (this.isToday) {
        return this.todayLabelValue
      } else if (this.isCurrentWeek) {
        return this.flatpickr.formatDate(date, this.currentWeekLabelFormatValue)
      } else if (this.isCurrentYear) {
        return this.flatpickr.formatDate(date, this.currentYearLabelFormatValue)
      } else {
        return this.flatpickr.formatDate(date, this.otherYearLabelFormatValue)
      }
    } else {
      return this.selectLabelValue
    }
  }

  get isToday () {
    let currentDate = this.value
    if (!currentDate) { return false }

    let startOfDay = new Date()
    startOfDay.setHours(0, 0, 0, 0)
    currentDate.setHours(0, 0, 0, 0)

    return startOfDay.getTime() == currentDate.getTime()
  }

  get isCurrentWeek () {
    let currentDate = this.value
    if (!currentDate) { return false }

    let rightNow = new Date()

    let monday = new Date(rightNow)
    monday.setDate(rightNow.getDate() - rightNow.getDay() + 1)
    monday.setHours(0, 0, 0, 0)

    let sunday = new Date(monday)
    sunday.setDate(monday.getDate() + 6)
    sunday.setHours(23, 59, 59, 999)

    return currentDate >= monday && currentDate <= sunday
  }

  get isCurrentYear () {
    let currentDate = this.value
    if (!currentDate) { return false }

    let rightNow = new Date()
    return rightNow.getFullYear() == currentDate.getFullYear()
  }

  // DATE PICKER CALLBACKS:

  isDisabledDate (date) {
    return this.bookable.isDisabledDate(date)
  }

  // DATE PICKER EVENTS:

  setDatePickerConfig (setting, value) {
    let current = this.flatpickr.config[setting]

    if (this.isDifferentObject(current, value)) {
      this.flatpickr.set(setting, value)
    }
  }

  // onChange gets triggered when the user selects a date, or changes the time on a selected date.
  datePickerChanged (selectedDates, dateAsString, flatpickr) {
    let booking = this.booking
    if (booking) {
      booking.fetchRangedAvailability(this.value, this.vlue)
    }
  }

  // Gets triggered when the calendar is opened.
  datePickerOpened (selectedDates, dateAsString, flatpickr) {
    super.datePickerOpened(selectedDates, dateAsString, flatpickr)
    this.refreshDatePickerAvailability(flatpickr)

    document.querySelector('.reservations-widget-step-one').classList.add('date-select-open')

    if (this.embedded) {
      this.embedded.title = this.selectLabelValue
      this.embedded.reportWidgetSize()
    }
  }

  // Gets triggered when the calendar is closed.
  datePickerClosed (selectedDates, dateAsString, flatpickr) {
    document.querySelector('.reservations-widget-step-one').classList.remove('date-select-open')

    if (this.embedded) {
      this.embedded.resetTitle()
      this.embedded.reportWidgetSize()
    }
  }

  // Gets triggered when the month is changed, either by the user or programmatically.
  datePickerMonthChanged (selectedDates, dateAsString, flatpickr) {
    super.datePickerMonthChanged(selectedDates, dateAsString, flatpickr)

    this.refreshDatePickerAvailability(flatpickr)

    if (this.embedded) {
      this.embedded.reportWidgetSize()
    }
  }

  // Gets triggered when the year is changed, either by the user or programmatically.
  datePickerYearChanged (selectedDates, dateAsString, flatpickr) {
    super.datePickerYearChanged(selectedDates, dateAsString, flatpickr)

    this.refreshDatePickerAvailability(flatpickr)

    if (this.embedded) {
      this.embedded.reportWidgetSize()
    }
  }

  // Take full control of every date cell by interacting with dayElement.
  createDatePickerDay (selectedDates, dateAsString, flatpickr, dayElement) {
    super.createDatePickerDay(selectedDates, dateAsString, flatpickr, dayElement)

    let booking = this.widget.booking
    if (booking && booking.isConfirmableDate(dayElement.dateObj)) {
      let confirmableElement = document.createElement('small')
      confirmableElement.classList.add('datepicker-confirmable')
      dayElement.appendChild(confirmableElement)
      dayElement.classList.add('is-confirmable-date')
    }
  }

  refreshDatePickerAvailability (flatpickr) {
    let firstDate = new Date(flatpickr.currentYear, flatpickr.currentMonth,     1) // first day of current month
    let lastDate  = new Date(flatpickr.currentYear, flatpickr.currentMonth + 2, 0) // last day of next month
    let booking   = this.widget.booking

    if (booking) {
      booking.fetchRangedAvailability(firstDate, lastDate)
    }
  }
}
