import _ from 'underscore'
import SelectScrollController from 'controllers/select_scroll_controller'
import RealtimeWidgetController from 'controllers/realtime_widget_controller'

export default class extends SelectScrollController {

  static targets = ['input']

  static outlets = ['realtime-widget']

  /**
   * @returns {RealtimeWidgetController}
   */
  get widget() {
    return this.realtimeWidgetOutlet
  }

  get value() {
    return parseInt(this.inputTarget.value)
  }

  set value(newValue) {
    if (newValue) { newValue = parseInt(newValue) }
    let option = this.options.find((option) => parseInt(option.value) == newValue)
    if (option) {
      this.inputTarget.value = newValue
    } else {
      this.inputTarget.value = ''
    }

    this.changed()
  }

  get options() {
    return Array.from(this.inputTarget.querySelectorAll('option'))
  }

  get maxGuests() {
    return parseInt(this.options.at(-1).value)
  }

  get minGuests() {
    return parseInt(this.options.at(0).value)
  }

  get label() {
    return this.options.find(option => {
      return this.value === parseInt(option.value)
    })?.innerText
  }

  connect() {
    _.defer(() => this.initializeSelectScroll())
  }

  selectValue(event) {
    super.selectValue(event)
    this.changed(event)
  }

  changed(event) {
    this.widget.refresh()
    this.widget.reportEvent('party-size-changed', { partySize: this.value })
  }

  set guestOptions(newGuests) {
    if (newGuests.length == 0) { return }

    let current = this.value
    let from    = newGuests.at(0)
    let till    = newGuests.at(-1)

    if (from != this.minGuests || till != this.maxGuests) {
      this.morph(this.inputTarget, newGuests.map(
        (guest) => {
          guest = parseInt(guest)
          let selected = (guest == current)  ? ' selected' : ''
          return `<option value="${guest}"${selected}>${guest}</option>`
        }
      ).join())
    }

    this.updateGuestOptions()
    this.refreshItems()
  }

  updateGuestOptions () {
    let booking      = this.widget.booking
    let availability = booking.availability

    if (availability) {
    }
  }

  refreshGuests() {
    let booking       = this.widget.booking
    this.guestOptions = _.range(
      booking.minimumGuestsValue,
      booking.maximumGuestsValue + 1
    )
  }
}
